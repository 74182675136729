/* eslint no-shadow: ["error", { "allow": ["state"] }]*/

import Vue from 'vue';

export const state = () => ({
	alertMessage: [],

	dataGrid: {
		gridItems: {
			items: [],
			paginator: {},
			searchTerm: '',
		},

		gridOptions: {
			paginationPage: 1,
			showDataGrid: false,
			usedSearchBar: null,
			usedFiltering: false,
			usedFilterPreset: false,
			offsetNum: 0,
		},
	},
});

export const mutations = {
	// ALERT MESSAGE -------------------------------------------------------------------------
	ADD_ALERT_MESSAGE (state, message) {
		state.alertMessage.push(message);
	},

	DELETE_ALERT_MESSAGE (state, { message }) {
		state.alertMessage.splice(state.alertMessage.indexOf(message), 1);
	},
	// END ALERT MESSAGE --------------------------------------------------------

	// DATA GRID ----------------------------------------------------------------
	ADD_DATA_GRID (state, payload) {
		for (const value in payload) {
			if (payload.hasOwnProperty(value)) {
				state.dataGrid.gridItems[value] = payload[value];
			}
		}
	},

	// Update funkce pro celý dataGrid objekt
	UPDATE_DATA_GRID_VALUES (state, payload) {
		Vue.set(state.dataGrid[payload.gridObject], payload.fieldName, payload.updatedValue);
	},
	// END DATA GRID ------------------------------------------------------------

	// RESET VALUES -------------------------------------------------------------
	RESET_GRID_FILTERS (state) {
		for (const value in state.dataGrid.gridFilters) {
			if (state.dataGrid.gridFilters.hasOwnProperty(value)) {
				state.dataGrid.gridFilters[value] = null;
			}
		}
	},
	// END RESET VALUES ----------------------------------------------------------
};

export const actions = {
	addDatagrid ({ commit }, payload) {
		commit('ADD_DATA_GRID', payload);
	},

	flashMessage ({ commit }, payload) {
		commit('ADD_ALERT_MESSAGE', payload);

		setTimeout(function () {
			commit('DELETE_ALERT_MESSAGE', payload);
		}, payload.duration ? payload.duration : 3000);
	},
};


